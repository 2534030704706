import React from 'react'
import { graphql } from 'gatsby'
import Image from 'gatsby-image'
import { FormattedMessage } from 'react-intl'

import Layout from '~components/layout'
import SEO from '~components/seo'
import CountyLookup from '~components/about/county-lookup'
import { NavCard } from '~components/shared'

const About = props => {
  const { section, locale } = props.pageContext
  const { location } = props
  const about = props.data.contentfulAbout
  const countyDirectoryData = props.data.allContentfulCountyDirectory.nodes
  const { settings } = props.data
  const { californiaStateSiteLink } = settings

  let title = ''
  let heroHeadline = ''
  let heroSubline = ''
  let heroImage = ''
  let heroNavCard1 = {}
  let heroNavCard2 = {}
  let heroNavCard3 = {}
  let mission = {}
  let missionFocusArea1 = {}
  let missionFocusArea2 = {}
  let missionFocusArea3 = {}
  let localLookup = {}
  let partnersDescription = ''
  let partnersLogos = []

  let newDocument = {
    ...about
  }

  const navCardClasses = 'md:w-1/2 lg:w-1/4 my-4 md:px-4'

  if (newDocument.Hero_Headline) {
    heroHeadline = newDocument.Hero_Headline
  }

  if (newDocument.Hero_Subline) {
    heroSubline = newDocument.Hero_Subline
  }

  if (newDocument.Hero_Image.fluid) {
    heroImage = {
      fluid: { ...newDocument.Hero_Image.fluid },
      style: {
        maxHeight: 592,
        position: 'absolute'
      }
    }
  }

  if (newDocument.Hero_NavCard1_Headline && newDocument.Hero_NavCard1_Subline && newDocument.Hero_NavCard1_AnchorLink) {
    let anchor = ''

    if (newDocument.Hero_NavCard1_AnchorLink) {
      if (newDocument.Hero_NavCard1_AnchorLink.charAt(0) === '#') {
        anchor = newDocument.Hero_NavCard1_AnchorLink
      } else {
        anchor = `#${newDocument.Hero_NavCard1_AnchorLink}`
      }
    }

    heroNavCard1 = (
      <div className={navCardClasses}>
        <NavCard
          color='purple'
          title={newDocument.Hero_NavCard1_Headline}
          caption={newDocument.Hero_NavCard1_Subline}
          uri={anchor}
        />
      </div>
    )
  }

  if (newDocument.Hero_NavCard2_Headline && newDocument.Hero_NavCard2_Subline && newDocument.Hero_NavCard2_AnchorLink) {
    let anchor = ''

    if (newDocument.Hero_NavCard2_AnchorLink) {
      if (newDocument.Hero_NavCard2_AnchorLink.charAt(0) === '#') {
        anchor = newDocument.Hero_NavCard2_AnchorLink
      } else {
        anchor = `#${newDocument.Hero_NavCard2_AnchorLink}`
      }
    }

    heroNavCard2 = (
      <div className={navCardClasses}>
        <NavCard
          color='orange'
          title={newDocument.Hero_NavCard2_Headline}
          caption={newDocument.Hero_NavCard2_Subline}
          uri={anchor}
        />
      </div>
    )
  }

  if (newDocument.Hero_NavCard3_Headline && newDocument.Hero_NavCard3_Subline && newDocument.Hero_NavCard3_AnchorLink) {
    let anchor = ''

    if (newDocument.Hero_NavCard3_AnchorLink) {
      if (newDocument.Hero_NavCard3_AnchorLink.charAt(0) === '#') {
        anchor = newDocument.Hero_NavCard3_AnchorLink
      } else {
        anchor = `#${newDocument.Hero_NavCard3_AnchorLink}`
      }
    }

    heroNavCard3 = (
      <div className={navCardClasses}>
        <NavCard
          color='green'
          title={newDocument.Hero_NavCard3_Headline}
          caption={newDocument.Hero_NavCard3_Subline}
          uri={anchor}
        />
      </div>
    )
  }

  if (newDocument.Mission_Quote_Highlight && newDocument.Mission_Quote) {
    mission = {
      highlight: newDocument.Mission_Quote_Highlight,
      quote: newDocument.Mission_Quote.Mission_Quote
    }
  }

  if (newDocument.Mission_FocusArea1_Headline && newDocument.Mission_FocusArea1_Subline) {
    missionFocusArea1 = {
      headline: newDocument.Mission_FocusArea1_Headline,
      subline: newDocument.Mission_FocusArea1_Subline
    }
  }

  if (newDocument.Mission_FocusArea2_Headline && newDocument.Mission_FocusArea2_Subline) {
    missionFocusArea2 = {
      headline: newDocument.Mission_FocusArea2_Headline,
      subline: newDocument.Mission_FocusArea2_Subline
    }
  }

  if (newDocument.Mission_FocusArea3_Headline && newDocument.Mission_FocusArea3_Subline) {
    missionFocusArea3 = {
      headline: newDocument.Mission_FocusArea3_Headline,
      subline: newDocument.Mission_FocusArea3_Subline
    }
  }

  if (newDocument.LocalLookup_Headline && newDocument.LocalLookup_Subline) {
    localLookup = {
      headline: newDocument.LocalLookup_Headline,
      subline: newDocument.LocalLookup_Subline,
      resultsHeadline: newDocument.LocalLookup_Results_Headline,
      resultsSubline: newDocument.LocalLookup_Results_Subline,
      resultsSummaryDefaultText: newDocument.LocalLookup_Results_CountySummaryDefaultText
    }
  }

  if (newDocument.Partners_Description) {
    partnersDescription = newDocument.Partners_Description
  }

  if (newDocument.Partners_Logos) {
    partnersLogos = newDocument.Partners_Logos.map((logo, id) => {
      return (
        <div key={id} className='w-1/3 lg:w-1/5 p-4 lg:p-8'>
          <div className='aspect-ratio-wide'>
            <img
              alt={logo.file.fileName}
              src={logo.file.url + '?fm=jpg&fl=progressive&w=320&q=60'}
              className='w-full h-full object-contain'
            />
          </div>
        </div>
      )
    })
  }

  return (
    <Layout locale={locale} location={location} section={section}>
      <SEO lang={locale} title={about?.metaTitle || about.title} description={about?.metaDescription} />
      <div className='relative'>
        <div className='relative overflow-y-hidden'>
          <div className='hidden lg:block lg:absolute lg:z-0 lg:w-full lg:h-full overflow-hidden'>
            <Image
              fluid={heroImage.fluid}
              className='lg:w-full lg:h-full shift-right'
              imgStyle={{ objectFit: 'cover', objectPosition: 'center' }}
              loading='eager'
              alt='N/A'
              aria-hidden
            />
          </div>
          <div className='relative z-10 max-w-page py-8'>
            <h2 className='headline-1 mb-4'>{heroHeadline}</h2>
            <p className='body-1 max-w-2xl'>{heroSubline}</p>
          </div>
        </div>
        <div className='max-w-page py-8'>
          <div className='flex flex-col md:flex-row md:flex-wrap lg:flex-no-wrap md:justify-center h-auto'>
            {heroNavCard1}
            {heroNavCard2}
            {heroNavCard3}
          </div>
          <div id='mission' className='headline-section-centered my-16'>
            <div>
              <FormattedMessage id='our-mission' />
            </div>
            <hr aria-hidden />
          </div>
          <div className='flex flex-col lg:flex-row mx-auto lg:px-48'>
            <div className='lg:w-1/5 mb-8 lg:mb-0 lg:pr-4'>
              <div className='extended text-gray-dark mb-3'>
                <FormattedMessage id='our-purpose' />
              </div>
              <div className='body-6 italic'>{mission.highlight}</div>
            </div>
            <div className='body-4 lg:w-4/5 lg:pl-4'>{mission.quote || null}</div>
          </div>
          <div className='flex flex-col md:flex-row my-16 md:-mx-4'>
            <div className='w-full md:w-1/3 px-4 mb-4 md:mb-0'>
              <div className='headline-4'>{missionFocusArea1.headline}</div>
              <div className='body-5 mt-2'>{missionFocusArea1.subline}</div>
            </div>
            <div className='w-full md:w-1/3 px-4 mb-4 md:mb-0'>
              <div className='headline-4'>{missionFocusArea2.headline}</div>
              <div className='body-5 mt-2'>{missionFocusArea2.subline}</div>
            </div>
            <div className='w-full md:w-1/3 px-4 mb-4 md:mb-0'>
              <div className='headline-4'>{missionFocusArea3.headline}</div>
              <div className='body-5 mt-2'>{missionFocusArea3.subline}</div>
            </div>
          </div>
          <div className='flex flex-col all-center my-16'>
            <div className='button button-blue-clear'>
              <a href={californiaStateSiteLink} target='_blank' rel='noopener noreferrer' className='w-72'>
                <FormattedMessage id='learn-more-state-site' /> <em className='fas fa-caret-right' />
              </a>
            </div>
          </div>
        </div>
        <div className='wave-top' />
        <CountyLookup localLookup={localLookup} countyDirectoryData={countyDirectoryData} />
        <div className='wave-bottom' />
        <div id='partners' className='max-w-page py-8'>
          <div className='headline-section-centered my-16'>
            <div>
              <FormattedMessage id='partners' />
            </div>
            <hr aria-hidden />
          </div>
          <div className='flex flex-col mx-auto lg:px-8'>
            <div className='body-4 lg:px-24 mb-8 lg:mb-0 text-center'>{partnersDescription}</div>
            <div className='body-4 lg:px-24 my-8 lg:mb-0 flex flex-wrap items-center justify-around'>
              {partnersLogos}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default About

export const aboutPageQuery = graphql`
  query aboutPageQuery($locale: String!) {
    contentfulAbout(node_locale: { eq: $locale }) {
      Title
      metaTitle
      metaDescription
      Hero_Headline
      Hero_Subline
      Hero_Image {
        fluid(maxWidth: 1440, quality: 80) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      Hero_NavCard1_Headline
      Hero_NavCard1_Subline
      Hero_NavCard1_AnchorLink
      Hero_NavCard2_Headline
      Hero_NavCard2_Subline
      Hero_NavCard2_AnchorLink
      Hero_NavCard3_Headline
      Hero_NavCard3_Subline
      Hero_NavCard3_AnchorLink
      Mission_Quote_Highlight
      Mission_Quote {
        Mission_Quote
      }
      Mission_FocusArea1_Headline
      Mission_FocusArea1_Subline
      Mission_FocusArea2_Headline
      Mission_FocusArea2_Subline
      Mission_FocusArea3_Headline
      Mission_FocusArea3_Subline
      LocalLookup_Headline
      LocalLookup_Subline
      LocalLookup_Results_Headline
      LocalLookup_Results_Subline
      LocalLookup_Results_CountySummaryDefaultText {
        json
      }
      Partners_Description
      Partners_Logos {
        file {
          url
          fileName
        }
      }
    }
    allContentfulCountyDirectory(filter: { node_locale: { eq: $locale } }, sort: { order: ASC, fields: county }) {
      nodes {
        id
        county
        phone
        slug
        title
        website
        zipCodes
        address {
          address
        }
        summary {
          json
        }
      }
    }
    settings: contentfulSettings(title: { eq: "Global Settings" }) {
      californiaStateSiteLink
    }
  }
`
